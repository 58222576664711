<template>
  <div>
    <v-tabs
      v-model="nav"
      align-tabs="start"
      height="35"
      color="primary"
      mandatory
    >
      <v-tab
        v-for="n in items"
        :key="n.key"
        :value="n.key"
        :to="n.to"
        variant="text"
        height="35"
        :rounded="tile ? 0 : undefined"
      >
        <Icon v-if="n.icon" class="mr-1" :name="n.icon" />
        <span class="text-3 font-500 normal-case">{{ n.title }}</span>
        <v-progress-circular
          v-if="n.loading"
          class="ml-2"
          size="20"
          width="1"
          indeterminate
        />
      </v-tab>
    </v-tabs>
    <v-divider />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
type MenuItem = {
  title: string;
  key?: string | number;
  icon?: string;
  color?: string;
  component?: any;
  loading?: boolean;
  props?: any;
  to?: any;
};

type ComputedMenuItem = MenuItem & {
  key: string | number;
  active: boolean;
};

export default defineComponent({
  name: "ContentNavMenu",
  props: {
    modelValue: { type: [String, Number] },
    menu: { type: Array as PropType<MenuItem[]>, required: true },
    disabled: { type: Boolean, default: false },
    remember: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    activeColor: { type: String, default: "black" },
    navRouteKey: { type: String, default: "nav" },
    tile: { type: Boolean, default: false },
  },
  mounted() {
    const routeValue = getQueryParam(this.navRouteKey);
    if (this.remember) {
      this.$watch("nav", (value: string) =>
        setQueryParam(this.navRouteKey, value)
      );
    }
    if (this.remember && routeValue) {
      this.nav = routeValue;
    } else if (!this.nav) {
      this.$nextTick(() => {
        if (this.menu.length) {
          this.nav = this.menu[0].key || 0;
        }
      });
    }
  },
  computed: {
    nav: {
      set(value: string | number | undefined) {
        this.$emit("update:modelValue", value);
      },
      get(): string | number | undefined {
        return this.modelValue;
      },
    },
    items() {
      return this.menu.map((item, i): ComputedMenuItem => {
        const active = this.nav == (item.key || i);
        return {
          ...item,
          key: item.key || i,
          active,
          loading: (this.loading && active) || item.loading,
        };
      });
    },
  },
});
</script>
